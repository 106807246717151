import React from 'react';

const LeftPathComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="108.12 96.79 256.07 106.1">
    <defs>
      <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0" style={{ stopColor: 'white', stopOpacity: 0 }} />
        <stop offset="0.5" style={{ stopColor: 'white', stopOpacity: 1 }} />
        <stop offset="1" style={{ stopColor: 'white', stopOpacity: 0 }} />
      </linearGradient>
    </defs>
    <g transform="matrix(-0.027267, 0, 0, 0.027267, 371.275024, 127.630905)" fill="url(#grad1)" stroke="none">
      <path d="M 260 2745 C 260 2719 336 2528 385 2431 C 541 2120 788 1828 1030 1668 C 1424 1407 1974 1234 2835 1101 C 3151 1052 3527 1007 4175 940 C 4997 855 5287 817 5615 754 C 6126 655 6358.76 567.958 6480 384 C 6540.563 255.346 6512.048 176.013 6456.563 61.088 C 6413.616 -27.867 6425.185 -85.745 6433.256 -131.492 C 6463.498 -302.9 6572.038 -354.928 6675.483 -423.322 C 6843.903 -534.89 7079.007 -608.841 7447.052 -665.693 C 7582.13 -686.639 7742.856 -705.874 8019.852 -734.515 C 8371.225 -770.849 8495.19 -787.092 8635.397 -814.023 C 8853.83 -856.341 8974.374 -908.064 9005.152 -972.183 C 9022.25 -1006.808 9012.419 -1038.012 8968.817 -1091.018 C 8947.444 -1117.093 8945.735 -1121.368 8952.574 -1123.932 C 8956.848 -1125.215 9089.361 -1127.78 9246.668 -1129.062 L 9533.068 -1131.2 L 9554.868 -1108.971 C 9595.477 -1067.508 9633.948 -1000.396 9645.918 -950.81 C 9648.91 -939.269 9651.047 -914.476 9651.047 -896.095 C 9650.619 -719.981 9411.668 -562.675 8979.504 -454.954 C 8826.9 -416.483 8755.086 -403.231 8537.081 -370.744 C 8237.002 -326.716 8138.259 -306.625 8016.859 -264.733 C 7825.356 -198.477 7649.891 -74.33 7727.04 19.956 C 7752.137 50.627 7766 64 7766 64 C 7861 161 7951 318 7979 434 C 7986 461 7991 519 7991 562 C 7990 974 7431 1342 6420 1594 C 6063 1684 5895 1715 5385 1791 C 4683 1894 4452 1941 4168 2039 C 3720 2194 3490 2420 3490 2705 L 3490 2760 L 1875 2760 C 453 2760 260 2758 260 2745 Z"></path>
    </g>
  </svg>
);

export default LeftPathComponent;

