import React, { useState, useEffect } from 'react';
import './ProBanner.css'; // Ensure this path is correct

const ProBanner = ({ height }) => {
  // const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  //   if (/android/i.test(userAgent)) {
  //     setIsMobile(true);
  //   }
  //   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //     setIsMobile(true);
  //   }
  // }, []);

  const bannerStyle = {
    height: height, // Apply the height from the prop
    lineHeight: 1.8, 
    fontSize: "1.5rem", 
    margin: "0px", 
    marginTop: "2px" 
  };

  return (
    <div className="banner" >
      <p style={bannerStyle}>PRO</p>
    </div>
  );
};

export default ProBanner;
