import React from 'react';
import worldMap from './worldMap.svg';
import './WorldMapComponent.css'
function WorldMapComponent() {
  return (
    <div>
      <img src={worldMap} alt="World Map" className='world-map-img' />
    </div>
  );
}

export default WorldMapComponent;