import React from 'react';
import './AutoScrollImages.css';

const AutoScrollImages = ({ photos }) => {
  const duplicatedPhotos = [...photos, ...photos];

  return (
    <div className="marquee">
      <div className="marquee-inner">
        <div className="marquee-content">
          {duplicatedPhotos.map((photo, index) => (
            <img key={index} src={photo} alt={`Photo ${index}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AutoScrollImages;
