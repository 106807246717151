import React from 'react';
import './PROSectionComponent.css';

const PROSectionComponent = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="22.6 89.15 373.37 241.89"
    className="fixed-height-svg"
    preserveAspectRatio="none"
>
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" x1="209.284" y1="89.154" x2="209.284" y2="331.041" id="gradient-0">
        <stop offset="0" style={{ stopColor: "rgb(100% 100% 100%)" }}></stop>
        <stop offset="1" style={{ stopColor: "rgb(56.687% 56.687% 56.687%)" }}></stop>
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" x1="209.284" y1="89.154" x2="209.284" y2="331.041" id="gradient-1" gradientTransform="matrix(0.90077, -0.434299, 0.456172, 0.946134, -51.253824, 110.759266)">
        <stop offset="0" style={{ stopColor: "rgb(185, 111, 177)" }}></stop>
        <stop offset="1" style={{ stopColor: "rgb(213, 180, 88)" }}></stop>
      </linearGradient>
      <filter id="inner-shadow-filter-0" colorInterpolationFilters="sRGB" x="-50%" y="-50%" width="200%" height="200%">
        <feOffset dx="0" dy="0"></feOffset>
        <feGaussianBlur stdDeviation="7"></feGaussianBlur>
        <feComposite operator="out" in="SourceGraphic"></feComposite>
        <feComponentTransfer result="choke">
          <feFuncA type="linear" slope="0.7"></feFuncA>
        </feComponentTransfer>
        <feFlood floodColor="#5a5a5abf" result="color"></feFlood>
        <feComposite operator="in" in="color" in2="choke" result="shadow"></feComposite>
        <feComposite operator="over" in="shadow" in2="SourceGraphic"></feComposite>
      </filter>
    </defs>
    <path d="M 22.598 109.254 C 199.673 194.467 218.469 35.608 395.969 109.254 L 395.969 300.622 C 235.865 236.998 181.936 393.857 22.598 300.622 L 22.598 109.254 Z"
          style={{ stroke: "url(#gradient-0)", strokeWidth: "0px", paintOrder: "fill markers", fill: "url(#gradient-1)", filter: "url(#inner-shadow-filter-0)" }}>
    </path>
  </svg>
);

export default PROSectionComponent;
