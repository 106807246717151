import React, { useEffect } from 'react';
import '../App.css';
import logo from '../assets/IOS logo (white).png';
import Navbar from '../components/sections/Navbar';
import Footer from '../components/sections/footer';
import './about.css'
import FacebookLogo from '../assets/icons/logos/facebook.png'
import TikTokLogo from '../assets/icons/logos/tiktok.png'
import InstagramLogo from '../assets/icons/logos/instagram.png'
import LinkedInLogo from '../assets/icons/logos/linkedin.png'
import AutoScrollImages from '../components/AutoScrollImages';
import NewYorkImage from '../assets/carousel/new-york.jpg';
import NorwayImage from '../assets/carousel/norway.jpg';
import SantoriniImage from '../assets/carousel/santorini.jpeg';
import PexelsCarloObrienImage from '../assets/carousel/pexels-carlo-obrien-402486657-15301144.jpg';
import PexelsIliaBronskiyImage from '../assets/carousel/pexels-ilia-bronskiy-1137858493-20888052.jpg';
import PexelsHolodnaImage from '../assets/carousel/pexels-holodna-17400602.jpg';
import PexelsAlecDouaImage from '../assets/carousel/pexels-alecdoua-17645285.jpg';
import PexelsKsuEliStudioImage from '../assets/carousel/pexels-ksu-eli-studio-78564297-8681433.jpg';
import PexelsAlinaSkazkaImage from '../assets/carousel/pexels-alinaskazka-25539612.jpg';

function About() {
  useEffect(() => {
    const socialItems = document.querySelectorAll('.social-item');
    socialItems.forEach((item, index) => {
      setTimeout(() => {
        item.classList.add('bounce-in');
      }, index * 300); // 300ms delay between each item
    });
  }, []);

  // Replace with the actual paths to your images
  const imagePaths = [
    NewYorkImage,
    NorwayImage,
    SantoriniImage,
    PexelsCarloObrienImage,
    PexelsIliaBronskiyImage,
    PexelsHolodnaImage,
    PexelsAlecDouaImage,
    PexelsKsuEliStudioImage,
    PexelsAlinaSkazkaImage
  ];


  return (
    <div className='container'>
      <Navbar />
      <div className='about-us-full-container'>
        <div className='about-us-container'>
          <div className='row justify-content-center'>
            <h1 className='title'>Lets travel together.</h1>
          </div>
          <div className='row justify-content-center'>
            <h1 className='subtitle'>The innovative app that revolutionizes multi-destination travel planning.</h1>
          </div>
          <div className='row justify-content-center'>
            <div className='socials-container'>
              <div className='follow-us-container'>
                <h1 className='follow-us-text'>
                  FOLLOW 
                  US
                </h1>
              </div>
              <div className='social-item'>
                <a href="https://www.facebook.com/people/TravelMe/61552792921180">
                  <img src={FacebookLogo} className="social-item-img" width="40" />
                </a>
              </div>
              <div className='social-item'>
                <a href="https://www.instagram.com/travelmeapp.co.uk/">
                  <img src={InstagramLogo} className="social-item-img" width="40" />
                </a>
              </div>
              <div className='social-item'>
                <a href="https://www.tiktok.com/@travelmeapp">
                  <img src={TikTokLogo} className="social-item-img" width="40" />
                </a>
              </div>
              <div className='social-item'>
                <a href="https://www.linkedin.com/company/travelmeapp">
                  <img src={LinkedInLogo} className="social-item-img" width="40" />
                </a>
              </div>
            </div>
          </div>
         
        </div>
      </div>
      <div className='row justify-content-center imageScroll' >
        <AutoScrollImages photos={imagePaths} />
      </div>
      <div className='row justify-content-center about-us-body-container'>
        <p className='about-us-body'>
        Our travel app is designed to simplify the planning process, allowing users to explore options freely without the pressure of committing to specific dates and details until they are ready. Our goal is to provide users with unique insights into destinations, enabling them to make informed decisions and craft comprehensive, adventurous itineraries.
        </p>
        <p className='about-us-body'>
        Recognizing the limitations of other standalone travel achievement tracking apps out there, we have integrated these features into our platform, automating the tracking process upon journey completion for a seamless and enhanced user experience.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default About;
