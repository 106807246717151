import React from 'react';

const PlanePathSeperator = () => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="626.000000pt" height="335.000000pt" viewBox="0 0 626.000000 335.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,335.000000) scale(0.100000,-0.100000)"
            fill="#FFFFFF" stroke="none" opacity={0.5}>
            <path d="M5737 2210 c-14 -12 -45 -47 -68 -80 -40 -58 -41 -58 -95 -62 -50 -3
-54 -1 -54 20 0 29 -14 28 -37 -2 -12 -16 -32 -25 -60 -29 -40 -5 -43 -3 -43
19 0 31 -9 30 -35 -1 -17 -21 -31 -26 -102 -31 -51 -4 -87 -12 -94 -21 -7 -7
-10 -15 -8 -16 2 -2 86 -21 186 -43 101 -21 183 -42 183 -46 0 -5 -34 -54 -75
-110 -80 -110 -88 -115 -176 -117 -27 -1 -52 -7 -59 -15 -12 -15 -4 -19 92
-40 18 -4 28 -14 30 -28 2 -16 10 -23 27 -23 19 0 32 -12 56 -52 41 -68 58
-57 39 25 -8 31 -14 66 -14 77 0 21 143 225 157 225 5 0 50 -72 102 -161 72
-124 96 -157 106 -149 11 9 10 21 -1 68 -23 94 -27 139 -15 146 19 12 13 39
-8 33 -28 -7 -46 66 -24 99 16 26 10 46 -12 38 -9 -4 -17 6 -24 33 -15 54 -16
51 39 128 39 53 50 76 48 99 -4 38 -25 44 -61 16z" />
            <path d="M4078 1853 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
            <path d="M3960 1810 c-14 -10 -23 -21 -20 -23 3 -3 19 6 35 19 35 28 22 32
-15 4z" />
            <path d="M4200 1826 c0 -3 15 -15 33 -27 23 -17 28 -18 18 -6 -14 18 -51 42
-51 33z" />
            <path d="M3876 1711 c-23 -43 -20 -52 5 -12 11 18 18 35 15 38 -2 3 -12 -9
-20 -26z" />
            <path d="M4301 1725 c0 -5 8 -26 19 -45 11 -19 19 -30 19 -25 0 6 -8 26 -19
45 -11 19 -19 31 -19 25z" />
            <path d="M3840 1570 c0 -22 3 -40 8 -40 4 0 6 18 4 40 -2 22 -6 40 -8 40 -2 0
-4 -18 -4 -40z" />
            <path d="M4342 1568 c-1 -14 -5 -35 -8 -45 -5 -14 -4 -15 5 -7 7 7 11 26 8 45
-3 28 -4 29 -5 7z" />
            <path d="M5255 1500 c-16 -16 -24 -30 -18 -30 10 0 34 24 47 48 12 20 2 14
-29 -18z" />
            <path d="M1600 1460 c-11 -8 -4 -10 28 -8 23 1 42 6 42 10 0 11 -52 10 -70 -2z" />
            <path d="M1758 1463 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
            <path d="M1881 1466 c10 -10 79 -15 79 -6 0 6 -19 10 -42 10 -23 0 -39 -2 -37
-4z" />
            <path d="M3870 1469 c0 -3 11 -20 25 -39 14 -19 25 -30 25 -24 0 10 -49 73
-50 63z" />
            <path d="M1325 1450 l-20 -9 20 1 c11 0 31 4 45 8 l25 8 -25 0 c-14 0 -34 -3
-45 -8z" />
            <path d="M1468 1453 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
            <path d="M2038 1453 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
            <path d="M2180 1450 c8 -5 28 -10 44 -10 39 0 30 6 -19 13 -27 4 -35 3 -25 -3z" />
            <path d="M4274 1428 l-29 -33 33 29 c30 28 37 36 29 36 -2 0 -16 -15 -33 -32z" />
            <path d="M1170 1442 c0 -4 18 -6 40 -4 22 2 40 6 40 8 0 2 -18 4 -40 4 -22 0
-40 -3 -40 -8z" />
            <path d="M2319 1437 c5 -5 24 -7 42 -5 33 3 33 4 -8 8 -23 3 -39 2 -34 -3z" />
            <path d="M1026 1432 c-2 -4 13 -6 35 -4 21 2 39 6 39 8 0 7 -69 4 -74 -4z" />
            <path d="M890 1419 c-11 -7 -4 -8 25 -3 50 8 58 14 19 14 -16 0 -36 -5 -44
-11z" />
            <path d="M2451 1426 c2 -2 22 -6 44 -9 27 -4 35 -3 25 3 -14 9 -79 15 -69 6z" />
            <path d="M5155 1405 c-16 -13 -27 -27 -24 -29 3 -3 18 8 34 24 35 36 31 38
-10 5z" />
            <path d="M765 1410 c-27 -7 -28 -8 -5 -8 14 0 34 3 45 8 25 11 2 11 -40 0z" />
            <path d="M2591 1406 c2 -2 22 -6 44 -9 28 -5 34 -4 20 3 -19 8 -73 14 -64 6z" />
            <path d="M618 1393 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z" />
            <path d="M475 1380 c-27 -7 -28 -8 -5 -8 14 0 34 3 45 8 25 11 2 11 -40 0z" />
            <path d="M2732 1384 c4 -3 23 -10 43 -14 21 -5 34 -5 29 -1 -10 10 -80 24 -72
15z" />
            <path d="M4157 1354 c-16 -9 -27 -18 -24 -21 6 -5 67 24 67 32 0 8 -14 5 -43
-11z" />
            <path d="M2876 1351 c9 -9 83 -27 74 -18 -3 3 -23 10 -44 16 -22 5 -35 6 -30
2z" />
            <path d="M3988 1331 c37 -35 39 -41 15 -42 -10 0 -13 -3 -7 -5 7 -3 27 2 45
10 19 9 25 14 13 11 -13 -3 -28 2 -40 16 -10 11 -28 25 -39 31 -12 5 -7 -3 13
-21z" />
            <path d="M5040 1315 c-19 -14 -29 -25 -23 -25 7 0 25 11 40 25 36 31 28 31
-17 0z" />
            <path d="M3016 1311 c17 -14 64 -31 64 -22 0 4 -16 13 -37 19 -20 6 -32 8 -27
3z" />
            <path d="M3165 1260 c11 -5 29 -9 40 -9 l20 0 -20 9 c-11 5 -29 9 -40 9 l-20
0 20 -9z" />
            <path d="M3885 1255 c-40 -14 -40 -14 -5 -9 19 4 39 10 45 15 11 11 10 11 -40
-6z" />
            <path d="M4100 1251 c24 -20 40 -26 40 -16 0 2 -15 12 -32 20 l-33 16 25 -20z" />
            <path d="M4920 1240 c-19 -11 -28 -19 -20 -19 8 0 26 8 40 19 31 25 25 25
-20 0z" />
            <path d="M3291 1236 c2 -2 20 -6 39 -10 25 -4 31 -3 20 4 -14 9 -69 15 -59 6z" />
            <path d="M3740 1230 c-35 -7 -35 -7 -5 -9 17 0 37 4 45 9 17 11 11 11 -40 0z" />
            <path d="M3430 1218 c0 -5 18 -8 40 -8 22 0 40 2 40 4 0 2 -18 6 -40 8 -22 2
-40 0 -40 -4z" />
            <path d="M3588 1213 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
            <path d="M4225 1185 c17 -8 37 -14 45 -14 8 0 0 6 -19 14 -42 19 -66 18 -26 0z" />
            <path d="M4781 1179 c-13 -5 -22 -12 -19 -15 3 -3 21 2 39 10 37 16 19 21
-20 5z" />
            <path d="M4340 1150 c0 -9 69 -24 76 -17 3 2 -13 9 -36 15 -22 5 -40 6 -40 2z" />
            <path d="M4640 1140 l-25 -8 25 0 c14 0 34 3 45 8 l20 9 -20 -1 c-11 0 -31 -4
-45 -8z" />
            <path d="M4498 1123 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
        </g>
    </svg>
);

export default PlanePathSeperator;
