import React from 'react';
import tickIcon from '../assets/icons/tick.png'; // Adjust the import according to your file structure
import './premiumFeatures.css'

const styles = {
  featureContentFeature: {
    borderRadius: '10px',
    width: "100%",
    marginTop: "40px",
    padding: "0 40px"
  },
  featureContentFeatureSubContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  featureContentFeatureIcon: {
    marginRight: '20px',
  },
  featureContentFeatureTextContainer: {
    flex: 1,
  },
  featureContentFeatureTitle: {

    color: "white",
    textAlign: "start",
    marginBottom: "0px"
  },
  featureContentFeatureDescription: {

    textAlign: "start",
    marginBottom: '-10px',
    color: "white",
  },
  bulletpointContainerFrame: {
    marginTop: '10px',
  },
  bulletPointContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bulletPoint: {
    marginRight: '5px',
    color:"white"
  },
  bulletPointText: {
    fontSize: '14px',
    color: "white"
  },
};

const SubscriptionUnlockedItem = ({ item }) => {
  return (
    <div style={styles.featureContentFeature}>
      <div style={styles.featureContentFeatureSubContainer}>
        <div style={styles.featureContentFeatureIcon}>
          <img src={tickIcon} alt="Tick Icon" style={{ width: '25px', height: '25px' }} />
        </div>
        <div style={styles.featureContentFeatureTextContainer}>
          <p class="featureContentTitle" style={styles.featureContentFeatureTitle}>{item.title}</p>
          <p class="featureContentDescription" style={styles.featureContentFeatureDescription}>{item.short_description}</p>
        </div>
      </div>
    </div>
  );
};

const UnlockedItems = () => {
  const unlockedItems = [
    {
      title: 'No Ads',
      short_description: 'Free from in-app advertisements',
      bulletpoints: [],
    },
    {
      title: 'Collaborate with Friends',
      short_description: 'Build your dream travels together',
      bulletpoints: [],
    },
    {
      title: 'AI Travel Builder',
      short_description: 'Destination-specific ratings and tips, AI Itinerary Builder, AI Recommendations',
      bulletpoints: [
        'Find the best season to go and why',
        'Discover new itinerary suggestions with our itinerary chat builder',
        'See how the destination scores in various criteria',
      ],
    },
    {
      title: 'Advanced Weather Insights',
      short_description: 'Find the best time to travel with monthly weather patterns for rain and temperature',
      bulletpoints: [],
    },
  ];

  return (
    <div class="premium-feature-frame">
      {unlockedItems.map((item, index) => (
        <SubscriptionUnlockedItem key={index} item={item} />
      ))}
    </div>
  );
};

export default UnlockedItems;
