import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import greeceCountryFlag from '../../assets/countries/GR.png';
import usCountryFlag from '../../assets/countries/US.png';
import icelandCountryFlag from '../../assets/countries/IS.png';
import './countryflagIcon.css'

function CountryFlagIcon({country}) {
    const countryMapper = {
        'GR':greeceCountryFlag,
        'US':usCountryFlag,
        'IS':icelandCountryFlag
    }
    return (
        <div className='countryFrame' style={{ backgroundImage: `url(${countryMapper[country]})` }}>
        </div>
    )
}

export default CountryFlagIcon;