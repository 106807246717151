import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import logo from '../../assets/IOS logo (white).png';
import '../../App.css';
import './footer.css';

function Footer() {
    return (
        <div className='footer'>
            <div className="row d-flex justify-content-center" >
                <img src={logo} className="app-logo-footer" alt="logo" width="25" />
            </div>
            <div class="d-flex justify-content-center" style={{margin: "30px 0"}}>
                <div class="bd-highlight"> 
                    <a class="nav-link nav-item-custom" href="/">Home</a>
                </div>
                <div class="bd-highlight"> 
                    <a class="nav-link nav-item-custom" href="/privacy">T&C</a>
                </div>
                <div class="bd-highlight"> 
                    <a class="nav-link nav-item-custom" href="https://travelmeapp.atlassian.net/servicedesk/customer/portal/3">Contact</a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
