import React from 'react';
import '../App.css';
import logo from '../assets/IOS logo (white).png';
import Navbar from '../components/sections/Navbar';
import Footer from '../components/sections/footer';

const Section = ({ title, content }) => (
  <div className="subsection">
    <h3 className="title">{title}</h3>
    <p className="content-paragraph">{content}</p>
  </div>
);

function Privacy() {
  const sections = [
    {
      title: '1. Information Collection:',
      content: 'TravelMe collects limited user information, including first and last name, email address, app preferences, and location (with user approval). For users under the age of 13, we comply with the Children\'s Online Privacy Protection Act (COPPA) and collect information only with parental consent.',
    },
    {
      title: '2. Information Use:',
      content: 'We use your personal information to enhance in-app visuals and to identify you to other users when using the premium sharing feature. This facilitates a personalized and interactive user experience on TravelMe, suitable for all age groups.',
    },
    {
      title: '3. Information Sharing and Disclosure:',
      content: 'TravelMe does not share personal data with third parties, ensuring the privacy and security of our users\' information.',
    },
    {
      title: '4. App Information and User Safety:',
      content: 'The safety ratings and information provided in this app are intended as general guidelines only. While we strive to ensure accuracy, conditions can change rapidly, and this information may not be complete or up to date. We recommend users exercise caution, verify information with other sources, and take additional precautions. This app assumes no liability for any harm, loss, or damages resulting from the use of the safety information provided. Users are encouraged to seek advice from local authorities or other reliable sources before traveling.'
    },
    {
      title: '5. User Rights and Choices:',
      content: 'Users have the right to update and remove their data/account through the app settings. For users under 13, parents or guardians can access, update, or delete their child’s information.',
    },
    {
      title: '6. Data Security:',
      content: 'Your personal data is securely stored in the cloud and is accessible only by you. Journey data is private and accessible only by the user, unless shared with others for viewing or editing purposes.',
    },
    {
      title: '7. Children’s Privacy:',
      content: 'TravelMe is committed to protecting the privacy of children. We do not knowingly collect personal information from children under 13 without obtaining parental consent. If we learn that we have collected personal information from a child under 13 without parental consent, we will delete that information as quickly as possible.',
    },
    {
      title: '8. Changes to This Privacy Policy:',
      content: 'TravelMe may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.',
    },
    {
      title: '9. Contact Us:',
      content: 'If you have any questions about this Privacy Policy or need to make inquiries regarding your child’s data, please contact us at contact@travelmeapp.co.uk.',
    },
    
  ];

  return (
    <div className='container'>
      <Navbar />
      <div className='full-container'>
        <div className='frontpage'>
          <h1 className='title'>Privacy Policy</h1>
          <h2 className='subtitle'>Privacy Policy for TravelMe, Last updated: 24/03/2025</h2>
          <p className='content-paragraph'>
            Welcome to TravelMe, the innovative app that revolutionizes multi-destination travel planning. Our platform offers an intuitive way to manage itineraries and expenses, providing unique recommendations and tips to enhance your travel experience. With features like global travel progression statistics, achievements, rankings, and map completion, TravelMe adds an engaging and competitive edge to your travel planning, while being child-friendly with content suitable for all ages.
          </p>
        </div>
        <div style={{paddingTop: 40}}>
          {sections.map((section, index) => (
            <Section key={index} title={section.title} content={section.content} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
