import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../App.css';
import Navbar from '../components/sections/Navbar';
import './home.css';
import './home-animations.css';
import norwayImage from '../assets/images/norway.jpg';
import santoriniImage from '../assets/images/santorini.jpeg';
import newyorkImage from '../assets/images/new-york.jpg';
import CountryFlagIcon from '../components/icons/countryflagIcon';
import WorldMapComponent from '../assets/svgs/worldMap';
import LeftPathComponent from '../assets/svgs/leftPathComponent';
import firstPreview from '../assets/images/first-preview-cropped.png';
import secondPreview from '../assets/images/second-preview-cropped.png';
import PROSectionComponent from '../assets/svgs/proContainer';
import PROimage from '../assets/travelme/PRO-banner.png';
import UnlockedItems from '../components/premiumFeatures';
import AppleStoreLogo from '../assets/icons/applestore.png';
import GooglePlayStoreLogo from '../assets/icons/googleplaystore.png';
import PlanePathSeperator from '../assets/svgs/planePathSeperator';
import Footer from '../components/sections/footer';
import ProBanner from '../components/icons/premium-logo';

function Home() {
  const { ref: firstFeatureRef, inView: firstFeatureInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: secondFeatureRef, inView: secondFeatureInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: premiumFeaturesRef, inView: premiumFeaturesInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className='container'>
      <Navbar />
      <div className='full-container'>
        <div className='row topsection'>
          <div className='col-lg-5 frontpage'>
            <h1 className="title">
              Your <span className="highlight-text">simple,</span> <br />multi-destination <br />travel app
            </h1>
            <h2 className="subtitle">
              Giving you the freedom to build your dream travel adventures, whilst tracking your progress along the way!
              </h2>
              <div className='downloadLinks'>
                <div className='store-logo-container'>
                  <a href="https://example.com">
                    <img src={AppleStoreLogo} width={"100%"} className='store-logo-backdrop'/>
                  </a>
                </div>
                <div className='store-logo-container' style={{marginTop: "-1px"}}>
                  <a href="https://example.com">
                    <img src={GooglePlayStoreLogo} width={"98%"} className='store-logo-backdrop'/>
                  </a>
                </div>
              </div>
          </div>
          <div className='col-lg-6 img-section'>
            <div className="sc-img-bg">
              <WorldMapComponent />
            </div>
            <div className='row img-container'>
              <div className='col'>
                <div className="sc-img sc-img-sm backdrop" style={{ backgroundImage: `url(${norwayImage})` }}>
                  <CountryFlagIcon country={"IS"} />
                </div>
                <div className="sc-img sc-img-sm backdrop" style={{ backgroundImage: `url(${santoriniImage})`, marginTop: "40px" }}>
                  <CountryFlagIcon country={"GR"} />
                </div>
              </div>
              <div className='col sc-img-container'>
                <div className="sc-img sc-img-lg backdrop" style={{ backgroundImage: `url(${newyorkImage})` }}>
                  <CountryFlagIcon country={"US"} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="first-seperator-frame">
          <LeftPathComponent />
        </div>
        <div className='row mobile-preview-container'>
          <div className={`row mobile-feature ${firstFeatureInView ? 'slide-fade-in-left' : 'hidden'}`} ref={firstFeatureRef}>
            <div className='col-lg-4 mobile-preview'>
              <div className="preview-image-container">
                <div className="circle-blur" />
                <img src={firstPreview} width={"80%"} className='phone-image' />
              </div>
            </div>
            <div className='col mobile-preview-description-container'>
              <h2 className="title">
                Build your journey
              </h2>
              <p className="description">
                Discover the ultimate travel companion with our flexible design, designed to make your trips seamless and unforgettable. Here’s what you can do:
              </p>
              <ul className="bulletpoints">
                <li><span className="bullet-title">Customizable Journey Builder:</span> Our intuitive interface makes it simple to adjust your plans on the go.
                </li>
                <li><span className="bullet-title">Expense and Itinerary Management:</span> Keep track of your travel itinerary and expenses effortlessly. Log your spending, categorize expenses.
                </li>
                <li><span className="bullet-title">Weather Forecasts:</span> Get up-to-date forecasts to help you pack appropriately and plan activities around the best weather conditions.
                </li>
                <li><span className="bullet-title">Compare Flight Prices:</span> Find the best deals and book flights directly within the app to save time and money.
                </li>
              </ul>
            </div>
          </div>
          <div className='row plane-seperator'>
            <PlanePathSeperator />
          </div>
          <div className={`row mobile-feature ${secondFeatureInView ? 'slide-fade-in-right' : 'hidden'}`} ref={secondFeatureRef} style={{ marginTop: "8%" }}>
            <div className='col-lg-4 order-lg-2 mobile-preview'>
              <div className="preview-image-container">
                <div className="circle-blur" />
                <img src={secondPreview} width={"80%"} className='phone-image' />
              </div>
            </div>
            <div className='col order-lg-1 mobile-preview-description-container'>
              <h2 className="title">
                Track your progress
              </h2>
              <p className="description">
                Monitoring your progress made simpler by automatically updating your global completion each time you finish a journey. Here's what you can do:
              </p>
              <ul className="bulletpoints">
                <li><span className="bullet-title">Visualize Your Travels:</span> See your completed countries with detailed metrics, making it easy to track where you’ve been and what’s left to explore.
                </li>
                <li><span className="bullet-title">Level Up with Objectives:</span> Engage with built-in objectives that encourage you to rank up through an interactive level system, adding a fun, gamified element to your travels.
                </li>
                <li><span className="bullet-title">Optional Focus on Certain Regions:</span> Special attention to European and USA travels with dedicated metrics and milestones, enhancing your journey within these regions.
                </li>
              </ul>
            </div>
          </div>
        </div>
          <div className={`premium-features-container ${premiumFeaturesInView ? 'fade-in' : 'hidden'}`} ref={premiumFeaturesRef}>          <PROSectionComponent />
          <div className="premium-content">
            <div className="title-wrapper">
              <div className="title-container">
                <h2 className="title">
                  Our Premium Features
                </h2>
              </div>
            </div>
            <UnlockedItems />
          </div>
          <div></div>
          <div className="pro-icon">
            {/* <div className="backshadow" /> */}
            <ProBanner />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
